import React, { useEffect } from "react";
import Layout from "../../../components/Layout";

const SuccessPage = ({ location }) => {
  const submitter = location.state;
  console.log(submitter);
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  }, []);

  return (
    <Layout>
      <section className="hero is-primary is-bold is-large">
        <div className="hero-body has-text-centered">
          <h1 className="title">
            Thank You {submitter ? submitter.name : ""} !
          </h1>
          <h1 className="title">We will contact you shortly &#9743;</h1>
        </div>
      </section>
    </Layout>
  );
};

export default SuccessPage;
